import { AskRequest, AskResponse, ChatRequest } from "./models";

export async function askApi(options: AskRequest): Promise<AskResponse> {
    console.log("Log test Ask");
    const response = await fetch("https://apim-cursus-001.azure-api.net/chat/score", {
        method: "POST",
        body: `{"${options.question}"}`
    });

    const reponse = await response.json();
    const parsedResponse: AskResponse = {
        answer: reponse.output,
        thoughts: null,
        data_points: []
    };
    // const parsedResponse: AskResponse = await response.json();
    // if (response.status > 299 || !response.ok) {
    //     throw Error(parsedResponse.error || "Unknown error");
    // }

    return parsedResponse;
}

/* Modify this function to adapt the front to be a chat*/
// New API call: https://apim-cla-test-westeu-001.azure-api.net/chat/score
//body should be like: {
//"chat_history":[{"inputs":{"question":"Hello"},"outputs":{"line_number":0,"output":"Bonjour! Comment puis-je vous aider aujourd'hui ?"}}],
//"question": "Avez-vous des formations dédiées aux entreprises ?"
//}
export async function chatApi(options: ChatRequest): Promise<AskResponse> {
    const chat_history = options.history.slice(0, -1).map((item, index) => {
        return {
            inputs: { question: item.user },
            outputs: { line_number: index, output: item.bot }
        };
    });
    const lastQuestion = options.history.length > 0 ? options.history[options.history.length - 1].user : "";
    const body = {
        chat_history,
        question: lastQuestion
    };
    const bodystring = JSON.stringify(body, null, 2);

    const response = await fetch("https://apim-cursus-001.azure-api.net/chat/score", {
        method: "POST",
        body: bodystring
    });

    const reponse = await response.json();

    //here we get the correct response
    //debugger;
    const parsedResponse: AskResponse = {
        answer: reponse.output,
        thoughts: null,
        data_points: []
    };
    // const parsedResponse: AskResponse = await response.json();
    // if (response.status > 299 || !response.ok) {
    //     throw Error(parsedResponse.error || "Unknown error");
    // }

    return parsedResponse;
    //return parsedResponse;
}
export async function chatApiOld(options: ChatRequest): Promise<Response> {
    const url = options.shouldStream ? "/chat_stream" : "/chat";
    const rep = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            overrides: {
                retrieval_mode: options.overrides?.retrievalMode,
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions
            }
        })
    });
    debugger;
    return rep;
}

export function getCitationFilePath(citation: string): string {
    return `/content/${citation}`;
}
